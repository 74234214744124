import React, { ReactElement } from 'react'
import { Typography, styled, Button, Stack } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Link from 'next/link'
import { NotificationLink } from 'components/modules/NotificationLink'
import { notificationSection as t } from 'public/locales/ja/components/elements/notificationSection'
import type { News } from 'types/news'

type NotificationSectionProps = {
  notifications: News[]
  isCorp?: boolean
}

export const NotificationSection: React.FC<React.PropsWithChildren<NotificationSectionProps>> = ({
  isCorp,
  notifications,
}): ReactElement => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <SectionTitle mb={4} variant={'h2'}>
        {t.title}
      </SectionTitle>
      <Stack spacing={2}>
        {notifications.map((notification, index) => (
          <NotificationLink
            key={index}
            id={notification.id}
            date={notification.publishedAt}
            title={notification.title}
            badge={notification.category?.name}
            badgeColor={notification.category?.color}
            isCorp={isCorp}
            content={notification.content}
          />
        ))}
      </Stack>
      <Link href="/notices" passHref legacyBehavior>
        <StyledButton variant={'outlined'} endIcon={<ChevronRightIcon />}>
          {t.seeMore}
        </StyledButton>
      </Link>
    </Stack>
  )
}

export const SectionTitle = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.spacing(5)};
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.spacing(4)};
    }
  `
)
const StyledButton = styled(Button)(
  ({ theme }) => `
    font-weight: 700;
    margin-top: ${theme.spacing(5)};
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    font-size: ${theme.typography.h6.fontSize};
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2)} ${theme.spacing(1.5)};
    }
  `
)

import React from 'react'

const NewRelicSnippet: React.FC = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `
          ;window.NREUM||(NREUM={});NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}};
          NREUM.loader_config={accountID:"4500827",trustKey:"4500827",agentID:"1120362580",licenseKey:"NRJS-0e78e104148ce8bd73b",applicationID:"1120362580"};
          NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-0e78e104148ce8bd73b",applicationID:"1120362580",sa:1};
          /*! For license information please see nr-loader-spa-1.278.2.min.js.LICENSE.txt */
          (function(){
            try {
              var e = document.createElement("script");
              e.src = "https://js-agent.newrelic.com/nr-loader-spa-1.278.2.min.js";
              e.defer = true;
              document.head.appendChild(e);
            } catch (err) {
              console.error("Failed to load New Relic script", err);
            }
          })();
        `,
      }}
    />
  )
}

export { NewRelicSnippet }

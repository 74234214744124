import React from 'react'
import Link from 'next/link'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ImageFlux } from 'components/elements/ImageFlux'
import { corporateInformationSection as t } from 'public/locales/ja/components/elements/corporateInformationSection'

export const CorporateInformationSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <Title variant={'h2'}>{t.title}</Title>
          <Description>{t.description}</Description>
          <Link href={'/corp'} passHref legacyBehavior>
            <StyledButton variant={'outlined'} size={'large'} endIcon={<ChevronRightIcon />}>
              <Typography fontWeight={'bold'}>{t.seeMoreButton}</Typography>
            </StyledButton>
          </Link>
        </ContentWrapper>
        <ImageContainer>
          <ImageFlux
            src={'/images/matching/corporate_information.jpg'}
            loading={'lazy'}
            width={'520'}
            height={'346'}
            alt="corporate_information"
          />
        </ImageContainer>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(
  ({ theme }) => `
  background: ${theme.blue.base}
`
)

const Container = styled(Stack)(
  ({ theme }) => `
  max-width: 1080px;
  margin: 0 auto;
  flex-direction: row;
  column-gap: ${theme.spacing(8)};
  padding: ${theme.spacing(8)} ${theme.spacing(4)} ${theme.spacing(9)} ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    row-gap: ${theme.spacing(5)};
    padding: ${theme.spacing(4)} ${theme.spacing(2)};
  }
`
)

const ContentWrapper = styled(Box)(
  () => `
  max-width: 500px;
`
)

const Title = styled(Typography)(
  ({ theme }) => `
  font-size: 40px;
  padding-bottom: ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    font-size: 24px;
    padding-bottom: ${theme.spacing(2)};
  }
`
)

const Description = styled(Typography)(
  ({ theme }) => `
  font-size: 20px;
  padding-bottom: ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
    padding-bottom: ${theme.spacing(5)};
  }
`
)

const StyledButton = styled(Button)(
  ({ theme }) => `
  display: flex;
  max-width: 185px;
  padding: ${theme.spacing(2)} ${theme.spacing(4)};
  ${theme.breakpoints.down('sm')} {
    margin: 0 auto;
  }
`
)

const ImageContainer = styled(Box)(
  () => `
  max-width: 520px;
  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
`
)

import merge from 'lodash/merge'
import { base } from './base'
import { dev } from './dev'
import { staging } from './staging'
import { production } from './production'

const env = process.env.APP_ENV

let override = {}
if (env === 'dev') override = dev
if (env === 'staging') override = staging
if (env === 'production') override = production

export const config: typeof base = merge(base, override)

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/ja'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.locale('ja')

export function formatYearMonth(date: string | Date): string {
  return dayjs(date).format('YYYY年MM月')
}

export function formatUsualDate(date: string | Date): string {
  return dayjs(date).format('YYYY/MM/DD H:mm')
}

export function formatMonthDay(date: string | Date): string {
  return dayjs(date).format('MM/DD H:mm')
}

export function formatDate(date?: string | Date | null, defaultValue?: string): string {
  if (!date) return defaultValue || ''
  return dayjs(date).format('YYYY年M月D日')
}

export function formatDateTime(time: string | Date | undefined | null): string {
  return dayjs(time).tz('Asia/Tokyo').format('YYYY年M月D日 H:mm')
}

export function formatDateTimezone(date: string | Date | null | undefined, format?: string): string {
  if (!date) return ''
  return dayjs(date)
    .tz('Asia/Tokyo')
    .format(format || 'YYYY/MM/DD HH:mm:ss')
}

export function isDateFormat(date: string): boolean {
  if (isNaN(Date.parse(date))) return false
  const dateRegex = /^(\d{4})\/(\d{1,2})\/(\d{1,2})?$/ // YYYY/MM/DD
  return dateRegex.test(date)
}

export function formatDateShort(date: string | Date): string {
  return dayjs(date).format('YYMMDD')
}

// for alliance
export function formatDateAl(date: string | Date): string {
  return dayjs(date).format('YYYY/MM/DD')
}

export function convertToJapanTimeAsDate(date: Date): string {
  // Momentオブジェクトを日本時間に変換
  return dayjs(date).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm:ssZ')
}

export function getDaysDiff(date: string | Date | null | undefined): string {
  if (!date) return ''
  return `${dayjs().startOf('day').diff(dayjs(date).startOf('day'), 'days')}日`
}

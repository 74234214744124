import axios, { AxiosError } from 'axios'
import { configureScope, captureException, withScope } from '@sentry/nextjs'
import { config } from 'config'

// cookie から manavi_session を取得する
// TODO: 別のファイルに移動する
const getSessionCookies = (): { [key: string]: string | undefined } => {
  const cookie = document.cookie
  const cookieArray = cookie.split('; ')
  const sessionCookies: { [key: string]: string | undefined } = {}

  const sessionTypes = ['admin', 'user', 'dm']
  sessionTypes.forEach((type) => {
    const sessionCookie = cookieArray.find((item) => item.startsWith(`manavi_${type}_session`))
    if (sessionCookie) {
      const [, session] = sessionCookie.split('=')
      sessionCookies[type] = session
    }
  })
  return sessionCookies
}

export function sentrySetUser(userId: number, role?: number) {
  if (config.ENABLE_SENTRY) {
    configureScope((scope) => {
      scope.setUser({ userId, role, host: window.location.host })
    })
  }
}

export function sendSentryLog(e: Error | AxiosError) {
  if (!config.ENABLE_SENTRY) return

  const sessionCookies = getSessionCookies()

  if (axios.isAxiosError(e)) {
    let contexts = {}
    const response = e.response
    const endpoint = response?.config?.url ?? ''
    const status = response?.status ?? ''
    const method = response?.config?.method ?? ''

    contexts = { response }

    withScope((scope) => {
      scope.setFingerprint(['{{ default }}', endpoint, String(status), method])
      captureException(e, {
        tags: {
          adminCookie: sessionCookies.admin,
          userCookie: sessionCookies.user,
          dmCookie: sessionCookies.dm,
        },
        contexts,
      })
    })
  } else {
    captureException(e, {
      tags: {
        adminCookie: sessionCookies.admin,
        userCookie: sessionCookies.user,
        dmCookie: sessionCookies.dm,
      },
    })
  }
}

import { useCallback, useState, useContext, createContext, FC } from 'react'
import axios from 'axios'
import { BATCH_JOB_STATUS } from 'const/batchJob'
import type { BatchJob } from 'types/batchJob'
import { NeedInterviewCommentWithRelation } from 'types/needInterview'
import { TaskCommentNotified } from 'types/taskComment'

interface DmNotificationContextType {
  batchJobs: BatchJob[]
  fetchBatchJobs: (isOnce?: boolean) => Promise<BatchJob[]>
  interviewComments: NeedInterviewCommentWithRelation[]
  fetchInterviewComments: () => Promise<NeedInterviewCommentWithRelation[]>
  taskComments: TaskCommentNotified[]
  fetchTaskComments: () => Promise<TaskCommentNotified[]>
}

const DmNotificationContext = createContext<DmNotificationContextType>({
  batchJobs: [],
  fetchBatchJobs: async () => [],
  interviewComments: [],
  fetchInterviewComments: async () => [],
  taskComments: [],
  fetchTaskComments: async () => [],
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDmNotification() {
  return useContext(DmNotificationContext)
}

export const DmNotificationProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [batchJobs, setBatchJobs] = useState<BatchJob[]>([])
  const [interviewComments, setInterviewComments] = useState<NeedInterviewCommentWithRelation[]>([])
  const [taskComments, setTaskComments] = useState<TaskCommentNotified[]>([])
  const fetchBatchJobs = useCallback(async (isOnce?: boolean) => {
    return await axios
      .get<BatchJob[]>('/api/batch-jobs')
      .then((res) => {
        setBatchJobs(res.data)
        if (
          res.data.some(
            (job) => job.status === BATCH_JOB_STATUS.started || job.status === BATCH_JOB_STATUS.initialized
          ) &&
          !isOnce
        ) {
          setTimeout(() => fetchBatchJobs(), 30 * 1000)
        }
        return res.data
      })
      .catch((e) => {
        console.warn(e)
        return []
      })
  }, [])
  const fetchInterviewComments = useCallback(async () => {
    return await axios
      .get<NeedInterviewCommentWithRelation[]>('/api/need-interview-comments/notified')
      .then((res) => {
        setInterviewComments(res.data)
        return res.data
      })
      .catch((e) => {
        console.warn(e)
        return []
      })
  }, [])
  const fetchTaskComments = useCallback(async () => {
    return await axios
      .get<TaskCommentNotified[]>('/api/task-comments/notified')
      .then((res) => {
        setTaskComments(res.data)
        return res.data
      })
      .catch((e) => {
        console.warn(e)
        return []
      })
  }, [])
  const value = {
    batchJobs,
    fetchBatchJobs,
    interviewComments,
    fetchInterviewComments,
    taskComments,
    fetchTaskComments,
  }
  return <DmNotificationContext.Provider value={value}>{children}</DmNotificationContext.Provider>
}
